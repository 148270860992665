<template>
  <v-sheet class="customer" style="">
    <v-row>
   
      <v-layout class="mx-2 w-100">
        <v-col md="2" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid purple">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label me-0 d-flex align-center"
                    style="font-weight: 600; font-size: 18px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="purple lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="purple darken-4" dark>person</v-icon>
                    </VAvatar>
                    <span class="d-flex align-center flex-wrap">
                      <span class="mr-2" style="max-width: 92px; display: inline-block"> New</span>
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip
                      color="purple"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ news }}</v-chip
                    >
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col md="2" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid green">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="green lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="green" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    Confirm</v-list-item-title
                  >
                </v-list-item-content>
                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="green" style="font-size: 18px; color: white; font-weight: 600">{{
                      Confirm
                    }}</v-chip>
                  </span>
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="2" class="pr-0">
          <v-card class="mx-auto" style="border: 0.5px solid cyan">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar :size="55" rounded color="cyan lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="cyan" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    Pending</v-list-item-title
                  >
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading">
                    <v-chip color="cyan" style="font-size: 18px; color: white; font-weight: 600">{{
                      pending
                    }}</v-chip></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="2" class="">
          <v-card class="mx-auto" style="border: 0.5px solid orange">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label d-flex align-center"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="orange lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <span style="max-width: 90px; display: inline-flex">Cancelled</span>
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="orange"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ canceled }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="2" class="">
          <v-card class="mx-auto" style="border: 0.5px solid blue">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label d-flex align-center"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-1">
                      <v-icon size="34" color="blue" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <span style="max-width: 90px; display: inline-flex">Completed</span>
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip color="blue" style="font-size: 18px; color: white; font-weight: 600">{{
                      completed
                    }}</v-chip></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col md="2" class="">
          <v-card class="mx-auto" style="border: 0.5px solid black">
            <v-card-actions>
              <v-list-item class="grow px-0">
                <v-list-item-content>
                  <v-list-item-title
                    class="custom-form-label d-flex align-center"
                    style="font-weight: 600; font-size: 16px"
                  >
                    <VAvatar
                      :size="55"
                      rounded
                      color="black lighten-5"
                      variant="tonal"
                      class="me-1"
                    >
                      <v-icon size="34" color="white" dark>mdi-calendar</v-icon>
                    </VAvatar>
                    <span style="max-width: 90px; display: inline-flex">No Show</span>
                  </v-list-item-title>
                </v-list-item-content>

                <div align="center" justify="end">
                  <span class="subheading"
                    ><v-chip
                      color="black"
                      style="font-size: 18px; color: white; font-weight: 600"
                      >{{ no_show }}</v-chip
                    ></span
                  >
                </div>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-layout>
    </v-row>
    <v-row>
      <v-col md="12" class="">
        <!-- <pre>{{ searchParam }}</pre> -->
        <v-row>
          <v-col class="d-flex flex-row align-center justify-content-between py-2">
            <h1 class="custom-header-blue-text m-0">Reservations</h1>

            <div class="d-flex">
              <div md="4" class="py-0 mr-3" style="width: 290px">
                <!-- kk - {{ range && Array.isArray(range) && range[0] }} -->
                <DateRangePicker
                  hide-details
                  :hideTopMargin="true"
                  v-on:clear="searchranges()"
                  :minDate="minDate"
                  v-on:change="searchrange()"
                  v-model="range"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="item-sale-duration"
                  placeholder="Date Range"
                  clearable
                ></DateRangePicker>
              </div>
              <div class="mr-3" style="width: 150px">
                <v-autocomplete
                  :items="statusList"
                  v-model="status_filter"
                  outlined
                  hide-details
                  item-color="cyan"
                  @change="findstatus"
                  placeholder="Select Status"
                  class="pt-0 mt-0"
                  item-text="title"
                  clearable
                  item-value="value"
                  :menu-props="{
                    contentClass: 'test-list',
                  }"
                >
                  <template v-slot:item="{ item }" class="custom_lists">
                    <div class="d-flex w-100 status-listing">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar
                          :size="30"
                          class="white--text"
                          :color="item.color"
                          text-color="white"
                        >
                          <template v-if="item && item.value && item.value == 1"
                            >{{ news }}
                          </template>
                          <template v-else-if="item && item.value && item.value == 5">{{
                            rejected
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 4">{{
                            canceled
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 3">{{
                            pending
                          }}</template>

                          <template v-else-if="item && item.value && item.value == 2">{{
                            Confirm
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 6">{{
                            no_show
                          }}</template>
                        </v-avatar>
                      </v-list-item-action>
                    </div>
                  </template>
                </v-autocomplete>
                

                <!--    <SelectInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                                        item-text="company_name" item-value="id" v-model="supplier_filter"
                                        v-on:change="searchsupplier()" style=" width: 150px;" placeholder="Select supplier">
                                    </SelectInput> -->
              </div>

              <div class="mr-3" style="width: 150px">
                <v-autocomplete
                  :items="events"
                  v-model="status_type"
                  outlined
                  hide-details
                  item-color="cyan"
                  @change="findtype"
                  clearable
                  placeholder="Select Event"
                  class="pt-0 mt-0"
                  item-text="name"
                  item-value="name"
                  :menu-props="{
                    contentClass: 'test-list',
                  }"
                >
                  <template v-slot:item="{ item }" class="custom_lists">
                    <div class="d-flex w-100 status-listing">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-avatar
                          :size="30"
                          class="white--text"
                      
                          text-color="white"
                        >
                          <template v-if="item && item.value && item.value == 1">{{}}</template>
                          <template v-else-if="item && item.value && item.value == 'Birthday'">{{
                            birthday
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 'Anniversary'">{{
                            anniversary
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 'Promotion'">{{
                            promotion
                          }}</template>
                          <template v-else-if="item && item.value && item.value == 'Event'">{{
                            event
                          }}</template>
                            <template v-else-if="item && item.value && item.name == 'Mothers Day'">{{
                            event
                          }}</template>
                          <template v-else>{{ family }}</template>
                        </v-avatar>
                      </v-list-item-action>
                    </div>
                  </template>
                </v-autocomplete>

                <!--    <SelectInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                                        item-text="company_name" item-value="id" v-model="supplier_filter"
                                        v-on:change="searchsupplier()" style=" width: 150px;" placeholder="Select supplier">
                                    </SelectInput> -->
              </div>
              <div class="mr-3">
                <!--    <SelectInput hide-details :hideTopMargin="true" :items="supplierLists" id="segment"
                                        item-text="company_name" item-value="id" v-model="supplier_filter"
                                        v-on:change="searchsupplier()" style=" width: 150px;" placeholder="Select supplier">
                                    </SelectInput> -->
              </div>
              <!-- <div class="mr-3">
                  <AutoCompleteInput
                    hide-details
                    :items.sync="statusList"
                    item-text="title"
                    item-value="value"
                    id="segment"
                    style="width: 150px"
                    placeholder="Select Status"
                    v-model="status_filter"
                    v-on:change="findstatus()"
                    class="test-list"
                  ></AutoCompleteInput>
                </div> -->
                <v-btn
  style="width: 10px;"
  color="blue darken-4"
  depressed
  tile
  class="mr-2 white--text "
  @click="openReservation"
>
<span class="large-plus">+</span>

</v-btn>
<div class="mr-3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue darken-4"
                      v-on="on"
                      v-bind="attrs"
                      depressed
                      tile
                      v-on:click="exportReservation"
                      class="ml-2 white--text"
                      >Export</v-btn
                    >
                  </template>
                  <span>Export</span>
                </v-tooltip>
              </div>

              <!-- <v-btn
                style="width: 10px"
                color="blue darken-4"
                depressed
                tile
                class="mr-2 white--text"
                @click="openReservation"
              >
                +
              </v-btn> -->
              <v-menu offset-y left transition="slide-y-transition" :close-on-content-click="false">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn
                        color="blue darken-4"
                        depressed
                        tile
                        v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        class="white--text"
                      >
                        <v-icon>edit_note</v-icon>
                      </v-btn>
                    </template>
                    <span>Columns arrangement and visibility</span>
                  </v-tooltip>
                </template>
                <v-list nav dense>
                  <v-list-item
                    draggable
                    v-on:dragstart="dragStart(index)"
                    v-on:dragend="dragEnd"
                    v-on:dragover="dragOver(index)"
                    v-on:mousedown="mouseEvent('mousedown')"
                    v-on:mouseup="mouseEvent('mouseup')"
                    v-for="(item, index) in defaultColDefs"
                    :key="item.field + '_' + index"
                    class="flex-column cursor-move"
                  >
              
                    <div class="d-flex flex-row align-center">
                      <v-checkbox
                        :ripple="false"
                        hide-details
                        color="#0d47a1"
                        v-model="defaultColShow"
                        v-bind:value="item.field"
                        :disabled="Number(item.fixed) || pageLoading"
                        v-on:change="updateVisible()"
                        class="m-0"
                      ></v-checkbox>
                      <v-list-item-title>{{ item.headerName }}</v-list-item-title>
                      <v-icon>drag_indicator</v-icon>
                    </div>
                    <v-divider
                      v-if="index < customThead.length - 1"
                      class="mt-2 mb-0"
                      style="width: 100%"
                    ></v-divider>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="12" class="mt-0 py-0">
        <SearchCriteria
          v-if="listingSearch"
          :search="listingSearch"
          search-string="Customer #, Full Name, Email, Phone No. and Address"
          v-on:close-search="resetSearch"
        ></SearchCriteria>
        <!-- style="height: calc(100vh - 200px);"  -->
        <v-simple-table fixed-header class="bt-table table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="heading in customThead"
                  :key="heading.title"
                  style="background-color: #f5f5f5"
                >
                  <div>{{ heading.headerName }}</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <!-- v-if="employeeList.length" -->
              <!-- {{ employeeList }} -->
              <template v-if="customers.length">
                <!-- <pre>{{customers}}</pre> -->
                <tr
                  v-for="(row, bkey) in customers"
                  :key="bkey"
                  :class="bkey % 2 === 0 && 'blue lighten-5'"
                  class="employee-listing-tr"
                
                >
                  <td v-for="(th, index) in customThead" :key="'key_' + index">
                    <div v-if="th.field === 'id'"></div>

                    <div v-else-if="th.field === 'actions'">
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            class="mx-2"
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="updateVoucher(row)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            fab
                            dark
                            x-small
                            color="red"
                            v-bind="attrs"
                            v-on="on"
                            v-on:click.stop.prevent="deleteConfirm(row)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                    <div v-else-if="th.field === 'person'" class="cursor-pointer">
  <ValueTemplate :title="'person'" :value="row.no_of_guest"></ValueTemplate>
  <span class="group pa-2" v-if="row && row.chatnotes && row.chatnotes.length">
    <i
      class="fas fa-comment"
      @click.stop="remarkPop(row)"
      :style="{ color: row.chatnotes.length ? 'green' : '' }"
    ></i>
  </span>
  <span class="group pa-2" v-else>
    <i class="fas fa-comment" @click.stop="remarkPop(row)"></i>
  </span>
</div>
                    <div v-else-if="th.field === 'source'" class="cursor-pointer"  v-on:click="routeToDetail(row.id)">
                      <template v-if="row?.source?.text">
                        <v-chip
                          small
                          outlined
                          label
                          class="text-capitalize"
                          :color="row?.source?.color"
                          >{{ row?.source?.text }}
                        </v-chip>
                      </template>
                      <template v-else> No Source </template>
                    </div>
                    <div
                      v-else-if="th.field === 'customer'"
                      class="cursor-pointer"
                     
                    >
                      <ValueTemplate
                        class="text-capitalize"
                        :title="'customer_name'"
                        :value="row.customer_name"
                      ></ValueTemplate>
                      <br />

                      <template v-if="row.check">
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="mt-1"
                                @click="openpersondialog(row)"
                              >
                                <span class="font-size-16 font-weight-500">
                                  Guest</span
                                >
                              </v-chip>
                            </template>
                    </div>

                    <div
                      v-else-if="th.field === 'date'"
                      class="cursor-pointer"
                      link
                      v-on:click="routeToDetail(row.id)"
                    >
                      <div>
                        <span v-if="row.booking_date" style="text-transform: uppercase">{{
                          formatedateTime(row.booking_date)
                        }}</span>
                      </div>

                      <span>{{ row.booking_time }}</span>
                    </div>

                    <div
                      v-else-if="th.field === 'type'"
                      class="cursor-pointer"
                      link
                      v-on:click="routeToDetail(row.id)"
                    >
                      <v-chip
                        small
                        v-if="row.type == 'Birthday'"
                        outlined
                        label
                        class="text-capitalize"
                        color="purple"
                        >{{ row.type }}
                      </v-chip>
                      <v-chip
                        small
                        v-else-if="row.type == 'Anniversary'"
                        outlined
                        label
                        class="text-capitalize"
                        color="green"
                        >{{ row.type }}
                      </v-chip>
                      <v-chip
                        small
                        v-else-if="row.type == 'Promotion'"
                        outlined
                        label
                        class="text-capitalize"
                        color="black"
                        >{{ row.type }}
                      </v-chip>
                      <v-chip
                        small
                        v-else-if="row.type == 'event'"
                        outlined
                        label
                        class="text-capitalize"
                        color="orange"
                        >{{ row.type }}
                      </v-chip>
                      <v-chip small v-else outlined label class="text-capitalize" color="red"
                        >{{ row.type }}
                      </v-chip>
                    </div>

                    <div
                      v-else-if="th.field === 'phone'"
                      class="cursor-pointer"
                      link
                      v-on:click="routeToDetail(row.id)"
                    >
                      <ValueTemplate
                        :title="' phone number'"
                        :value="row.customer_number"
                      ></ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'email'" class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined>
                        <ValueTemplate :title="'Email'" :value="row.email"></ValueTemplate>
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'job_type'" class="cursor-pointer">
                      <v-chip color="#0D47A1" outlined link v-on:click="routeToDetail(row.id)">
                        <ValueTemplate
                          style="text-transform: uppercase"
                          :title="' job type'"
                          :value="row.role_name"
                        ></ValueTemplate>
                      </v-chip>
                    </div>
                    <div v-else-if="th.field === 'dob'" class="cursor-pointer">
                      <ValueTemplate :title="'DOB'" :value="row.date_of_birth"> </ValueTemplate>
                    </div>
                    <div v-else-if="th.field === 'status'" class="cursor-pointer">
                      <template v-if="type == 0">
                        <div class="text-center mx-2" span="2">
                          <div class="">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  outlined
                                  class="py-2"
                                  small
                                  :color="getStatusData(row.status, 'color')"
                                  v-bind="attrs"
                                  v-on="on"
                                  style="font-size: 12px; font-weight: bold"
                                >
                                  <v-icon size="16">{{ getStatusData(row.status, "icon") }}</v-icon>
                                  <span class="px-1">{{ getStatusData(row.status, "title") }}</span>
                                  <v-icon size="16">mdi-chevron-down</v-icon>
                                </v-btn>
                              </template>
                              <v-card width="180">
                                <v-list dense class="py-0">
                                  <template v-for="(item, index) in statusList">
                                    <v-list-item
                                    :disabled="getStatusData(row.status, 'title') === item.title"
                                      @click="currentSelectTitle(item, row)"
                                      :key="index"
                                      :class="{
                                        'grey lighten-3':
                                          getStatusData(row.status, 'title') == item.title,
                                      }"
                                    >
                                      <v-list-item-icon class="my-1 mr-2">
                                        <v-icon :color="item.color">{{ item.icon }}</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider
                                      :key="index"
                                      v-if="index != -1"
                                      class="my-0"
                                      style="width: 100%"
                                    ></v-divider>
                                  </template>
                                </v-list>
                              </v-card>
                            </v-menu>
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <div class="text-center mx-2" span="2">
                          <template>ERGGD</template>
                        </div>
                      </template>
                    </div>
                    <div v-else-if="th.field === 'country'" class="cursor-pointer">
                      <ValueTemplate
                        :title="'no after probation period'"
                        :value="row.country"
                      ></ValueTemplate>
                    </div>
                    <div
                      v-else-if="th.field === 'added_at' && th.status"
                      link
                      v-on:click="routeToDetail(row.id)"
                    >
                      <p class="my-auto py-2">
                        <v-chip class="py-0" small outlined color="primary" label>
                          <ValueTemplate
                            :value="row?.added_by?.display_name"
                            title="Admin"
                          ></ValueTemplate>
                          <!-- {{ row[getColValue(th.title)]?.value[0].createdBy_admin }} -->
                        </v-chip>
                      </p>
                      <p>
                        <v-chip small label style="font-weight: 600">
                          <!-- <ValueTemplate :value="row.formatDate" title="Created Date"></ValueTemplate> -->
                          {{ formatedateTimes(row.added_at) }}
                          <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                        </v-chip>
                      </p>
                    </div>
                    <div
                      v-else-if="th.field === 'updated_at' && th.status"
                      link
                      v-on:click="routeToDetail(row.id)"
                    >
                      <template v-if="row.updated_at">
                        <!-- {{ formatedateTimes(row.updated_at) }} -->
                        <!-- <p class="my-auto py-2">
                      <v-chip class="py-0" small outlined color="primary" label>
                        <ValueTemplate :value="row[getColValue(th.title)]?.value[0].createdBy_admin"
                          title="Created by admin"></ValueTemplate>
                      </v-chip>
                    </p> -->
                        <p>
                          <v-chip small label style="font-weight: 600">
                            {{ formatedateTimes(row.updated_at) }}
                            <!-- {{ row[getColValue(th.title)]?.value[0].created_date }} -->
                          </v-chip>
                        </p>
                      </template>
                      <template v-else>
                        <p style="font-size: 16px; font-weight: 600">no modified</p>
                      </template>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="10">
                    <p class="m-0 text-center">
                      <img
                        width="30"
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image mr-4"
                      />
                      Uhh... There are no reservations at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
            <tfoot v-else>
              <tr v-for="idr in 10" :key="`_$skeleton_$_locader_${idr}`">
                <td v-for="idk in 10" :key="idk">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
        <v-layout v-if="customers.length" class="light-border-top mt-4">
          <v-flex md6>
            <label class="btx-label p-4 pb-0"
              >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
            >
          </v-flex>
          <v-flex md6>
            <v-pagination
              color="blue darken-4"
              v-model="currentPage"
              :length="pageLimit"
              :total-visible="7"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <OrderTemplate
          v-model="order"
          :customer-id="customerId"
          :dialog="orderDialog"
          v-on:close="orderDialog = false"
          v-on:save="saveOrder()"
          v-on:collect:voucher="getAvailableVouchers"
          v-on:claim:voucher="getCollectedVouchers"
        ></OrderTemplate>
        <CollectTemplate
          :dialog="collectDialog"
          :order-id="cOrderId"
          :order-code="cOrderCode"
          :vouchers="cVouchers"
          v-on:close="vCollected()"
          v-on:collected="vCollected()"
        ></CollectTemplate>
        <RedeemTemplate
          :dialog="redeemDialog"
          :order-id="rOrderId"
          :order-code="rOrderCode"
          :vouchers="rVouchers"
          v-on:close="vRedeemed()"
          v-on:redeemed="vRedeemed()"
        ></RedeemTemplate>
        <DeleteTemplate
          type="Reservation"
          :delete-text="deleteText"
          :delete-dialog="deleteDialog"
          :delete-endpoint="deleteEndpoint"
          v-on:close="deleteDialog = false"
          v-on:delete:success="getCustomers()"
        ></DeleteTemplate>
        <SearchTemplate
          :dialog="allCollectedDialog"
          v-on:close="allCollectedDialog = false"
        ></SearchTemplate>
        <!-- <Dialog dense :dialog="whatsappPromotional" v-if="whatsappPromotional">
          <template v-slot:title> Whatsapp Promotional </template>
          <template v-slot:body>
            <div class="mx-6" fluid>
              <v-row class="mx-6">
                <v-col md="2">
                  <label for="item-file-upload" class="btx-label mt-2 required">Template</label>
                </v-col>
                <v-col md="8">
                  <SelectInput
                    hide-details
                    hideTopMargin
                    :loading="pageLoading"
                    :disabled="pageLoading"
                    item-text="text"
                    dense
                    item-value="value"
                    :items.sync="templateList"
                    v-model="whatsapp.template"
                  ></SelectInput>
                </v-col>
              </v-row>
              <v-row class="mx-6">
                <v-col md="2">
                  <label for="item-file-upload" class="btx-label mt-2 required">File</label>
                </v-col>
                <v-col md="8">
                  <v-file-input
                    v-model="whatsapp.files"
                    ref="fileInput"
                    hide-details
                    id="item-file-upload"
                    label="Upload Images"
                    outlined
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              @click="whatsappPromotional = false"
            >
              Send
            </v-btn>
            <v-btn :disabled="pageLoading" depressed tile v-on:click="whatsappPromotional = false">d
              Cancel
            </v-btn>
          </template>
        </Dialog> -->
        <Dialog :dialog="dialog" :dialogWidth="1200">
          <template v-slot:title>Notes ({{ customer_name }})<span v-if="customer_number">({{ customer_number }})</span></template>
          <template v-slot:body>
              <NotesTab3
                :createUrl="url"
                :relatedType="types"
                :relatedId="id"
                :relatedUrl="url"
                :getUrl="url"
              ></NotesTab3>
           
          </template>
          <template v-slot:action>
            <v-btn depressed tile color="red darken-4" @click="closedialog">
              <span style="color: white">Close</span>
            </v-btn>
          </template>
        </Dialog>
        <Dialog :dialog="statusdialog" :dialogWidth="900">
          <template v-slot:title>Reason for Status Change</template>
          <template v-slot:body>
            <div class="col-md-12">
              <TextAreaInput
                v-model="reason"
                auto-grow
                :disabled="pageLoading"
                :loading="pageLoading"
                id="notes"
                v-on:keypress="(e) => manageLimit(e)"
                  v-on:paste="(e) => onPaste(e)"
                placeholder=""
              ></TextAreaInput>
            </div>
          </template>
          <template v-slot:action>
            <v-btn
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              :disabled="excelLoading"
              :loading="excelLoading"
              v-on:click="saveReason(reason, status_new.value, status_id)"
            >
              Save
            </v-btn>
            <v-btn depressed tile color="red darken-4" @click="statusdialogs">
              <span style="color: white">Close</span>
            </v-btn>
          </template>
        </Dialog>
        <Dialog :dialog="persondialog" :dialogWidth="900">
          <template v-slot:title>Guest Detials</template>
          <template v-slot:body>
        <v-container>
          <v-row>
           
            <v-col md="12" class="py-1">
              <!-- <div class="custom-header-blue-text text-h5 text-center mb-2">Record Payment</div> -->
              <div>
                        <v-icon color="disabled" size="28" class="me-1">mdi-account</v-icon>
                        <b class="text-capitalize" style="font-size:15px;">{{person.booking_name}}</b>
                      </div>
                      <div class="mt-1" >
                        <v-icon color="disabled" size="28" class="me-1">mdi-phone</v-icon>

                        <b style="font-size:15px;" class="text-capitalize">{{person.booking_number}}</b>
                      </div>
                      <div  class="mt-1">
                        <i  style="color: grey;" class="fas fa-comment me-1"> </i> 

                        <b class="text-capitalize" style=" font-size:15px;">{{person.message}}</b>
                      </div>
            </v-col>
          </v-row>
          <template> </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn class="ml-2" depressed tile v-on:click="closepersondialog"> Cancel </v-btn>
      </template>
        </Dialog>
      </v-col>
    </v-row>

    <CreateReservation
      v-on:success="getCustomers()"
      :dialog="CreateReservation"
      :data="reservation"
      :key="rerenderKey"
      :sources="sources"
      @close="getCustomers()"
    ></CreateReservation>
  </v-sheet>
</template>

<script>
// import Chip from "@/view/components/Chip";
import Dialog from "@/view/components/Dialog";
import SearchCriteria from "@/view/components/SearchCriteria";
import { GET_RESERVATIONS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { GET_EVENTS } from "@/core/lib/common.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import OrderTemplate from "@/view/components/OrderTemplate";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import ValueTemplate from "@/view/components/ValueTemplate";
import SearchTemplate from "@/view/components/SearchAllVouchers";
import { filter, map, find } from "lodash";
import moment from "moment-timezone";
import { POST } from "@/core/services/store/request.module";
import { SearchEventBus } from "@/core/lib/search.lib";
import CreateReservation from "./Create-update";
import NotesTab3 from "./NotesTab3";
import JwtService from "@/core/services/jwt.service";

import TextAreaInput from "@/view/components/TextAreaInput";

import { QUERY } from "@/core/services/store/request.module";

import {
  SET_SEARCH_TITLE,
  SET_SEARCH_MODEL,
  SET_SEARCH_BAR,
  BEFORE_DESTROY,
} from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { SET_MESSAGE } from "@/core/services/store/common.module";

import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
//import SelectInput from "@/view/components/SelectInput.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
//import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
  name: "customer-listing",
  title: "Listing Customer",
  data() {
    return {
      reason: null,
      sources: [],
      rerenderKey: Number(new Date()),

      customer_name: null,
      status_filter: null,
      status_new: null,
      status_id: null,
      lastMonth: 0,
      customer_number:null,
      excelLoading: null,
      month: null,
      persondialog:false,
      statusdialog: false,
      status_type: null,
      news: 0,
      url: "reservation-note",

      types: "reservation",
      events: [
        // { text: "Birthday", value: "Birthday", color: "purple" },
        // { text: "Anniversary", value: "Anniversary", color: "green" },
        // { text: "Promotion", value: "Promotion", color: "black" },
        // { text: "Event", value: "Event", color: "orange" },
        // { text: "Family & Friends Gathering", value: "Family & Friends Gathering", color: "red" },
      ],
      dropdownFilter: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "This Week",
          value: "this_week",
        },
        {
          text: "This Month",
          value: "this_month",
        },
      ],
      dialog: false,
      currentStatus: {
        icon: "mdi-arrow-up-bold-box-outline",
        title: "New",
        value: 1,
        color: "purple",
      },
      statusList: [
        {
          icon: "mdi-arrow-up-bold-box-outline",
          title: "New",
          value: 1,
          color: "purple",
        },
        {
          icon: "mdi-checkbox-marked-circle",
          title: "Confirm",
          value: 2,
          color: "green",
        },
        {
          icon: "mdi-checkbox-marked-circle",
          title: "Completed",
          value: 5,
          color: "blue",
        },
        {
          icon: "mdi-progress-clock",
          title: "Pending",
          value: 3,
          color: "cyan",
        },
        {
          icon: "mdi-cancel",
          title: "Cancelled",
          value: 4,
          color: "orange",
        },
        
        {
          icon: "mdi-cancel",
          title: "No Show",
          value: 6,
          color: "black",
        },
      ],
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      whatsapp: {
        files: [],
        template: "first",
      },

      Confirm: 0,
      pending: 0,
      rejected: 0,
      canceled: 0,
      no_show: 0,
      new: 0,

      birthday: 0,
      anniversary: 0,
      promotion: 0,
      event: 0,
      family: 0,

      filterType: "all",
      searchEnabled: false,
      searchParam: null,
      CreateReservation: false,
      filterDateRange: null,
      defaultColDefs: [],
      range: null,
      customThead: [],
      reservation: {},
      speedDial: [],
      whatsappPromotional: false,
      customers: [],
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      type: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    currentPage() {
      this.getCustomers();
    },
    listingSearch() {
      this.getCustomers();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getCustomers();
      }
    },
  },
  methods: {
    exportReservation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "reservations/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      downloadURL.searchParams.append("range", this.range);
      if(this.searchParam){
        downloadURL.searchParams.append("search", this.searchParam);

      }


      //downloadURL.searchParams.append("search", this.queryParams.search);
      // this.map(this.queryParams, function (row, index) {
      //   downloadURL.searchParams.append(index, row);
      // });

      // this.map(this.listingSearch, function (row, index) {
      //   // console.log(index, "indexdd")

      //   if (index == "dates") {
      //     if (row && row[0]) {
      //       downloadURL.searchParams.append("dates[0]", row[0]);
      //     }
      //     if (row && row[1]) {
      //       downloadURL.searchParams.append("dates[1]", row[1]);
      //     }
      //   } else {
      //     downloadURL.searchParams.append(index, row);
      //   }

      //   //  downloadURL.searchParams.append(index, row);
      // });
      window.open(downloadURL, "_blank");
    },
    async getUnits() {
        const _this = this;
        try {
          _this.pageLoading = true;
          _this.categories = await GET_EVENTS({ search: this.search });
          this.events= _this.categories
          
        } catch (error) {
          _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          _this.pageLoading = false;
          _this.dialog = false;
        }
      },
    openpersondialog(row){
      this.person = row
       this.persondialog = true;
    },
    closepersondialog() {
      this.persondialog = false;
    },
    statusdialogs(){
      this.reason=null;
      this.statusdialog=false;
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.reason;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 249) {
        let trimValue = finalval.substring(0, 250);
        this.reason = trimValue;
        e.preventDefault();
      }
    },
    manageLimit(e) {
      if (this.reason && this.reason.length > 249) {
        e.preventDefault();
      }
    },
    closedialog() {
      this.dialog = false;
      this.getCustomers();
    },
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: "reservation-sources",
        })
        .then((data) => {
          this.sources = data.sources;
          console.log(this.sources);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    saveReason(reason, status, id) {
      if (!this.reason) {
        this.$store.commit(SET_ERROR, [{ model: true, message: "The reason field is required" }]);
        return false;
      }

      const _this = this;
      _this.pageLoading = true;
      this.$store
        .dispatch(POST, {
          url: `create-reason`,
          data: {
            reason: this.reason,
            status,
            id: id,
          },
        })
        .then((data) => {
          _this.reservation_status = data;
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Reason  saved Successfully." },
          ]);

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
      console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.statusdialog = false;

          this.statusChange(status, id);

          //  this.getCustomers();
        });
    },

    searchranges() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.range = null;
        this.getCustomers();
      }
    },
    searchrange() {
      if (this.range?.length == 2) {
        console.log(this.range, "range");
        this.date = this.range;
        const date1 = new Date(this.range[0]);
        const date2 = new Date(this.range[1]);

        if (date1 > date2 && date1 != date2) {
          this.$store.commit(SET_ERROR, [
            { model: true, message: "End Date must be after Start Date" },
          ]);
          return false;
        } else {
          this.getCustomers();
        }
        // this.getItems();
      }
    },
    findstatus() {
      this.getCustomers();

      console.log(this.status_filter, "status_filter");
    },
    findtype() {
      this.getCustomers();

      console.log(this.status_type, "status_filter");
    },
    remarkPop(row) {
      this.text = row.message;
      this.id = row.id;
      this.customer_name = row.customer_name;
      this.customer_number = row.customer_number;

      console.log(row, "row");
      this.dialog = true;
    },
    getStatusData(status, type) {
      let statusData = find(this.statusList, (row) => row.value == status);

      console.log(statusData, status, "test");
      return statusData[type];
    },
    currentSelectTitle(item, row) {
      //     this.currentStatus.title = item.title;
      // this.currentStatus.icon = item.icon;
      // this.currentStatus.color = item.color;
      this.statusdialog = true;
      this.status_new = item;
      this.status_id = row.id;
      console.log(item);
      console.log(row);

      // this.statusChange(item.value,row)
    },
    openReservation() {
      this.CreateReservation = true;
      this.reservation = {};
    },
    statusChange(status, id) {
      const _this = this;
      _this.pageLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: `change-reservation-status`,
          data: {
            status,
            id: id,
          },
        })
        .then((data) => {
          _this.reservation_status = data;
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Reservation Status Updated Successfully." },
          ]);

          console.log(_this.reservation_status, "_this.customer_email");

          /*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
 console.log(_this.purchaseOrder.supplier.barcode)     */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
          this.getCustomers();
        });
    },

    updateVoucher(row) {
      this.CreateReservation = true;
      this.reservation = row;
      // this.voucher.name=row?.vendor
      // this.voucher.id=row?.vendor_id
      console.log(row, "row");

      // this.subcategory.id = row?.id;
      // this.subcategory.name = row?.name;
    },
    handleReservationSuccess() {
      this.getCustomers();
    },
    formatedateTime(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      // const dragStartElement = this.defaultColDefs[this.dragStartIndex];
      // console.log(dragStartElement,'dragStartElement')
      // this.defaultColDefs.splice(this.dragStartIndex, 1);
      // this.defaultColDefs.splice(this.dragOverIndex, 0, dragStartElement);
      this.$nextTick(() => {
        this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.getCustomers();
          });
      });
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }
      // console.log(this.defaultColDefs);

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then((data) => {
            this.customThead = data;
            // console.log(data)
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.getCustomers();
          });
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    getAllCollectedVouchers() {},
    // changeDatePicker() {
    //   if (this.filterDateRange[0] && this.filterDateRange[1]) {
    //     this.getCustomers()
    //   }
    // },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getCustomers();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "customer-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "reservation-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `reservation/${id}`;
      this.deleteDialog = true;
    },
    async getCustomers(search = null) {
      try {
        let form = {
          current_page: this.currentPage,
          search: search,
          filter_type: this.filterType,
          status: this.status_filter,
          type: this.status_type,
          date: this.range,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }

        this.pageLoading = true;
        const {
          rows,
          totalPages,
          showingFrom,
          showingTo,
          totalRows,
          theads,
          news,
          no_show,
          canceled,
          completed,
          pending,
          Confirm,
          family,
          event,
          promotion,
          anniversary,
          birthday,
          month,
          lastMonth,
        } = await GET_RESERVATIONS(form);
        this.customers = rows;

        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.month = month;
        this.lastMonth = lastMonth;
        this.family = family;
        this.event = event;
        this.promotion = promotion;
        this.anniversary = anniversary;
        this.birthday = birthday;

        this.news = news;
        this.no_show = no_show;
        this.canceled = canceled;
        this.completed = completed;
        this.pending = pending;
        this.Confirm = Confirm;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
        let thead = theads;
        this.defaultColDefs = thead;
        this.customThead = [];
        for (let index = 0; index < thead.length; index++) {
          let element = thead[index];
          if (element.visible == 1) {
            this.customThead.push(element);
          }
        }

        let t_out = filter(thead, (row) => Number(row.visible) == 1);
        this.defaultColShow = map(t_out, "field");
        this.rerenderKey = Number(new Date());

        console.log(this.customThead);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
        this.CreateReservation = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {
          this.$store.dispatch(SET_SEARCH_MODEL, null);
        });
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    let _this = this;
    SearchEventBus.$on("start-search", (argument) => {
      _this.searchEnabled = false;
      _this.searchParam = argument;
      if (_this.searchParam) {
        _this.searchEnabled = true;
      }
      this.getCustomers(argument);
    });
    SearchEventBus.$emit("search-template", true);
    this.getCustomers();
    this.getData();
    this.getUnits();

    // await this.getTemplateListing();
    this.$store.dispatch(SET_SEARCH_BAR, true);
    this.$store.dispatch(SET_SEARCH_TITLE, "Reservations");
  },
  beforeDestroy() {
    this.$store.dispatch(BEFORE_DESTROY);
  },
  components: {
    // Chip,
    DeleteTemplate,
    OrderTemplate,
    CollectTemplate,
    RedeemTemplate,
    ValueTemplate,
    SearchTemplate,
    SearchCriteria,
    CreateReservation,
    //AutoCompleteInput,
    //SelectInput,
    TextAreaInput,
    DateRangePicker,
    Dialog,
    NotesTab3,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
    minDate() {
    if (this.range && Array.isArray(this.range) && this.range[1]) {
      return null;
    } else {
      return this.range && Array.isArray(this.range) && this.range[0]
    }
  }
  },
};
</script>

<style>
.customer-table .v-data-table__wrapper {
  height: calc(100vh - 205px) !important;
}
.customer-listing-tr:nth-child(even):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
  background-color: #e3f2fd !important;
  position: relative;
  z-index: 99;
}

.customer-listing-tr:nth-child(odd):hover {
  box-shadow: 0px 0px 10px #666666 !important;
  -webkit-box-shadow: 0px 0px 10px #666666 !important;
  -moz-box-shadow: 0px 0px 10px #666666 !important;
}
.large-plus {
  font-size: 30px; /* Adjust as needed */
  line-height: 1;

}
</style>
